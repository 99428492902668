import removeAccents from "remove-accents";
import { filter, join, replace, pipe } from "lodash/fp";

const designerSeparator = /×/g;
const urlify: (arg0: string) => string = pipe([
  (str) => str.toLowerCase(),
  replace(/[^-\w\s\u4E00-\u9FA5]/g, " "), // replace non-word chars with spaces
  replace(/^\s+|\s+$/g, ""), // trim leading/trailing spaces
  replace(/[-\s]+/g, "-"), // convert spaces to hyphens
]);

/*
 * This function matches the URL pattern that Rails router helpers create.
 */
const slugify: (...args: Array<number | string>) => string = pipe([
  (...args) => args,
  filter((part) => !!part),
  join(" "),
  replace(designerSeparator, "x"),
  removeAccents,
  urlify,
]);
export default slugify;
